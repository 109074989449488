import React from 'react';

export const CurrencySelectorContext = React.createContext<{
  isDisabled: boolean;
  setIsDisabled: React.Dispatch<React.SetStateAction<boolean>>
}>({
  isDisabled: false,
  setIsDisabled: () => { }
})

const CurrencySelectorProvider: React.ReactFCWithChildren = ({ children }) => {
  const [isDisabled, setIsDisabled] = React.useState(false)

  return (
    <CurrencySelectorContext.Provider value={{ isDisabled, setIsDisabled }}>
      {children}
    </CurrencySelectorContext.Provider>
  )
}

export default CurrencySelectorProvider
