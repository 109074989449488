import React, { useState } from 'react';
import classNames from 'classnames';
import { AppRoutes } from 'src/lib/routes';
import { Country, City } from 'src/types';
import Link from 'next/link';
import ImageWithFallback from './ImageWithFallback';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import useBreakpoints from 'src/hooks/useBreakpoints';

type Props = {
  cities: Record<number, City[]>;
  countries: Country[];
  onSelect?: (country?: Country) => void;
};

const Destinations: React.FC<Props> = ({ cities, countries, onSelect }) => {
  const { isScreenMd } = useBreakpoints()
  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const [showAll, setShowAll] = useState(false)
  const numShownItem = isScreenMd ? 10 : 3

  return selectedCountry ? (
    <>
      <div
        className="tw-flex tw-items-center tw-cursor-pointer hover:tw-font-semibold"
        onClick={() => {
          setSelectedCountry(undefined)
          onSelect && onSelect(undefined)
        }}
      >
        <FiChevronLeft size={18} className="tw-mr-2 tw-align-top" />
        <span className="tw-text-sm tw-leading-4">Back to Home</span>
      </div>

      <div className="tw-border-t tw-border-grey-lighter tw-my-6"></div>

      <div className="tw-font-semibold tw-mb-2">
        <Link legacyBehavior href={selectedCountry.slug === 'singapore' ? AppRoutes.cityRoute({ id: 'singapore' }).toPath() : AppRoutes.countryRoute({ id: selectedCountry.slug }).toPath()}>
          <a className="tw-text-brand-navy hover:tw-underline tw-font-styleSans tw-text-3xl tw-leading-none tw-mb-3">
            {selectedCountry.name}
          </a>
        </Link>
      </div>
      <div className="tw-flex tw-flex-wrap">
        {cities[selectedCountry.id].map((city) => (
          <div key={city.id} className="tw-w-full md:tw-w-1/2 tw-flex-shrink-0 hover:tw-font-semibold tw-py-1">
            <Link legacyBehavior href={AppRoutes.cityRoute({ id: city.slug }).toPath()}>
              <a className="tw-text-brand-navy tw-leading-none">
                {city.name}
              </a>
            </Link>
          </div>
        ))}
      </div>
    </>
  ) : (
    <div>
      <div className="tw-font-styleSans tw-text-3xl tw-font-semibold tw-mb-4">Destinations</div>
      <div className="row">
        <div className="col-5">
          <div className="tw-font-semibold tw-mb-1.5">All Countries</div>
          {countries.map((country, index) => (
            <div
              key={country.id}
              className={classNames(
                'tw-flex tw-justify-between tw-items-center tw-cursor-pointer tw-py-1 tw-text-brand-navy hover:tw-font-semibold tw-leading-none'
              )}
              onClick={() => {
                setSelectedCountry(country);
                onSelect && onSelect(country)
              }}
            >
              <span className="">{country.name}</span>
              <FiChevronRight size={20} />
            </div>
          ))}
        </div>

        <div className="col-7 tw-pl-6">
          <div className="tw-font-semibold tw-mb-1.5">Most Popular</div>
          {cities[0].map((city) => (
            <Link legacyBehavior key={city.id} href={AppRoutes.cityRoute({ id: city.slug }).toPath()}>
              <a>
                <div
                  className="tw-flex tw-items-center tw-py-1.5 tw-leading-none tw-text-brand-navy hover:tw-font-semibold"
                >
                  <span className="tw-text-brand-navy">{city.name}</span>
                </div>
              </a>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Destinations;
