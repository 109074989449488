import React, { useState } from 'react';

type ControlHeaderPageNavProps = {
  showHeader: boolean;
  showFooter: boolean;
  hideHeaderFilter: boolean;
  setShowHeader: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFooter: React.Dispatch<React.SetStateAction<boolean>>;
  setHideHeaderFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ControlHeaderPageNavContext = React.createContext<ControlHeaderPageNavProps>({
  showHeader: true,
  showFooter: true,
  hideHeaderFilter: false,
  setShowHeader: () => {},
  setShowFooter: () => {},
  setHideHeaderFilter: () => {},
});

const ControlHeaderPageNavProvider: React.ReactFCWithChildren = ({ children }) => {
  const [showHeader, setShowHeader] = useState<boolean>(true);
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const [hideHeaderFilter, setHideHeaderFilter] = useState<boolean>(false);
  return (
    <ControlHeaderPageNavContext.Provider value={{ showHeader, setShowHeader, showFooter, setShowFooter, hideHeaderFilter, setHideHeaderFilter }}>
      {children}
    </ControlHeaderPageNavContext.Provider>
  );
};

export default ControlHeaderPageNavProvider;
