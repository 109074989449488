import { Currency, TMoney, FilterOption, Pagination } from 'src/types'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import defaultHeroImage from 'public/images/collection_default.jpg';
import { Image } from 'src/types';

export const VERCEL_ENV = process.env.NEXT_PUBLIC_VERCEL_ENV

export const MasterCurrency: Currency = 'SGD'
export const PAYNOW_CURRENCY: Currency = 'SGD'
export const GRABPAY_CURRENCIES: Currency[] = ['SGD', 'MYR']

export const CardExpDateFormat = 'MM/YYYY'
export const UIDateFormat = 'DD MMM YYYY'
export const UIMonthFormat = 'MMM YYYY'
export const UIFullDateFormat = 'DD MMM YYYY, ddd'
export const UIDayFormat = 'ddd, DD MMM'
export const UIMonthYearFormat = 'MMMM YYYY'
export const DBDateFormat = 'YYYY-MM-DD'
export const UITimeFormat = 'hh:mmA'
export const DBTimeFormat = 'HH:mm'
export const UIDateTimeFormat = 'DD MMM YYYY, HH:mm'
export const DEFAULT_IMAGE = '/images/default.jpeg'

export const commonCarouselSettings = {
  dots: false,
  infinite: false,
  autoplay: false,
  arrows: true,
  prevArrow: (
    <div className="slick-prev">
      <FiChevronLeft strokeWidth={2} />
    </div>
  ),
  nextArrow: (
    <div className="slick-next">
      <FiChevronRight strokeWidth={2} />
    </div>
  )
};

export const RECAPTCHA_KEY = 'g-recaptcha-response'
export const ZERO_AMOUNT: TMoney = {
  amount: 0,
  currency: 'SGD'
}

export const BLOG_KINDS: { [key: string]: string } = {
  quick_read: 'Pocket Guide',
  complete_guide: 'Deep Dive',
  itinerary: 'Itinerary',
  list: 'The Roundup',
  tried_and_tested: 'Secret Find',
  video: 'Video'
};

export const BLOG_KIND_VALUES: { [key: string]: number } = {
  quick_read: 0,
  complete_guide: 1,
  itinerary: 2,
  list: 3,
  tried_and_tested: 4,
  video: 5
}

export const REFERRAL_AMOUNT: TMoney = {
  amount: 10,
  currency: 'SGD'
}

export const REFERRAL_PAYOUT_AMOUNT: TMoney = {
  amount: 10,
  currency: 'SGD'
}

export const sortOptions: FilterOption[] = [
  { label: 'Recommended', value: 'recommended' },
  { label: 'Top Rated', value: 'most_popular' },
  { label: 'Price: Low to High', value: 'asc' },
  { label: 'Price: High to Low', value: 'desc' },
  { label: 'Recently Added', value: 'latest' },
];

export const timeOfDayOptions: FilterOption[] = [
  { label: 'Morning', value: 'morning', desc: 'Start before 12pm' },
  { label: 'Afternoon', value: 'afternoon', desc: 'Start after 12pm' },
  { label: 'Evening', value: 'evening', desc: 'Start after 5pm' },
];

export const childAgeOptions: FilterOption[] = [
  { label: '0-3 years', value: '0_3' },
  { label: '4-7 years', value: '4_7' },
  { label: '8-12 years', value: '8_12' },
];

export const durationOptions: FilterOption[] = [
  { label: 'Less than 1 day', value: 0 },
  { label: '1 day', value: 1 },
  { label: '2 days', value: 2 },
  { label: '3 days', value: 3 },
];

export const tripTypeOptions: FilterOption[] = [
  { label: 'Private Trip', value: 'private' },
  { label: 'Join-in Trip', value: 'join_in' },
];

export const badgeKinds: { [key: string]: string } = {
  wildlifeSafe: 'wildlife_safe',
  litterFreeLeaveNoTrace: 'litter_free_leave_no_trace',
  lowCarbon: 'low_carbon',
  refusePlastics: 'refuse_plastics',
  communityBased: 'community_based',
  womenOwned: 'women_owned',
};

export const VIMEO_UNSUPPORTED_COUNTRIES = ['ID']

export const DefaultCollectionHeroImage: Image = {
  id: 0,
  name: 'default',
  contentType: 'image/jpg',
  caption: undefined,
  url: defaultHeroImage.src,
  xlUrl: defaultHeroImage.src,
  lgUrl: defaultHeroImage.src,
  mdUrl: defaultHeroImage.src,
  smUrl: defaultHeroImage.src,
  webpXlUrl: defaultHeroImage.src,
  webpLgUrl: defaultHeroImage.src,
  webpMdUrl: defaultHeroImage.src,
  webpSmUrl: defaultHeroImage.src,
  blurUrl: defaultHeroImage.src,
  blurBase64: ''
};

export const DEFAULT_PAGINATION: Pagination = {
  currentPage: 1,
  totalPages: 1,
  prevPage: 0,
  nextPage: 0,
  isFirstPage: true,
  isLastPage: false,
  totalCount: 0,
  limitValue: 1,
  offset: 0
}

export const ALPHABET_MAP: { [key: string | number]: string } = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
  5: 'F',
  6: 'G',
  7: 'H',
  8: 'I',
  9: 'J',
  10: 'K',
  11: 'L',
  12: 'M',
  13: 'N',
  14: 'O',
  15: 'P',
  16: 'Q',
  17: 'R',
  18: 'S',
  19: 'T',
  20: 'U',
  21: 'V',
  22: 'W',
  23: 'X',
  24: 'Y',
  25: 'Z'
}


export const HEADER_HEIGHT = 72

export const CONVERSION_FEE_RATE = 0.02
export const CONVERSION_FEE_MULTIPLIER = 1.02

export const STRIPE_ACCOUNT_COUNTRY_CODE = 'SG'

export const STRIPE_FRIENDLY_ERROR_MESSAGES: { [key: string]: string | undefined } = {
  payment_intent_authentication_failure: 'The provided payment method has failed authentication',
  undefined: undefined
}
