import qs from 'qs';
import { match, compile } from 'path-to-regexp';
import humps from 'humps';
import { omit } from 'lodash-es';

const API_BASE_URL = (process.env.NEXT_PUBLIC_BACKEND_BASE_URL || '') + '/api/storefront';
const APP_BASE_URL = process.env.NEXT_PUBLIC_APP_BASE_URL || '';
const ADMIN_BASE_URL = process.env.NEXT_PUBLIC_BACKEND_BASE_URL || '';

export type UrlHelper = (
  pathParams?: { [key: string]: string | number | Record<string, any> },
  queryParams?: Record<string, any>
) => Route;
type Routes<T> = { [prop in keyof T]: UrlHelper };

class Route {
  private path: string;
  private query: string;
  private baseUrl: string;
  private anchor: string;

  constructor(
    baseUrl: string,
    pathRegexp: string,
    pathParams: { [key: string]: string | number | Record<string, any> },
    queryParams?: Record<string, any>
  ) {
    let compiled = compile(pathRegexp);
    this.path = compiled(pathParams);
    this.baseUrl = baseUrl;
    this.query = queryParams
      ? qs.stringify(humps.decamelizeKeys(omit(queryParams, ['arrayFormat'])), {
          arrayFormat: queryParams['arrayFormat'] || 'brackets',
          addQueryPrefix: true,
        })
      : '';
    this.anchor = pathParams.anchor ? `#${pathParams.anchor}` : '';
  }

  toPath() {
    return this.path + this.query + this.anchor;
  }

  toUrl() {
    return this.baseUrl + this.toPath();
  }
}

function buildRoute(pathRegexp: string, baseUrl: string): UrlHelper {
  return (pathParams = {}, queryParams?) => {
    return new Route(baseUrl, pathRegexp, pathParams, queryParams);
  };
}

const adminRoutes = {
  previewGiftExperienceRoute: '/options/:id/preview_gift_experience',
  previewGiftCardRoute: '/gifts/preview',
  adminEditActivityRoute: '/admin/adventures/:id',
  editAdminCategorizableRoute: '/admin/categorizables/:id/edit',
  editAdminCollectionRoute: '/admin/collections/:id/edit',
  adminCollectionsRoute: '/admin/collections',
  adminOptionRoute: '/admin/options/:id',
  adminOptionsRoute: '/admin/options',
  adminTravelItineraryRoute: '/admin/travel_itineraries/:id/edit',
  adminTravelItinerariesRoute: '/admin/travel_itineraries',
  adminEditBlogRoute: '/admin/magazine/:id/edit',
  adminBlogsRoute: '/admin/magazine',
  editAdminActivityRoute: '/admin/activities/:id',
  adminActivitiesRoute: '/admin/activities',
  passwordRoute: '/users/password',
  authGoogleRoute: '/users/auth/google_oauth2/callback',
  authFacebookRoute: '/users/auth/facebook/callback',
  authEmailRoute: '/users/login',
  logoutRoute: '/users/logout',
  usersRoute: '/users',
  adminRoute: '/admin',
  partnerRoute: '/partner',
  apiWaitlistsRoute: '/api/waitlists',
  searchRoute: '/search',
};

const apiRoutes = {
  apiPaymentsRoute: '/payments',
  apiPaymentCardRoute: '/payment_cards/:id',
  apiPaymentCardsRoute: '/payment_cards',
  apiUnreadChatMessagesRoute: '/chat_messages/unread',
  apiChatMessagesRoute: '/chat_conversations/:id/chat_messages',
  apiReadChatMessagesRoute: '/chat_conversations/:id/read',
  apiChatConversationsRoute: '/chat_conversations',
  apiCountCookieConsentRoute: '/cookie_consents/count',
  apiMapImageRoute: '/map_images',
  apiSendFacebookPixelEventRoute: '/facebook_pixel_event',
  apiGiftCodesRoute: '/gift_codes',
  apiAddGiftCodeRoute: '/gift_codes/:id/add',
  apiRedeemGiftCodeRoute: '/gift_codes/:id/redeem',
  apiRemoveGiftCodeRoute: '/gift_codes/:id/remove',
  apiGiftEpxerienceCodeRoute: '/gift_codes/:id/gift_experience',
  apiSupplierInvitationRoute: '/supplier_invitations/:id',
  apiLoginWithWhatsappRoute: '/whatsapp_authentications/login',
  apiGetLastLoginMethodRoute: '/last_login_methods/:id',
  apiSendVerificationCodeToWhatsappRoute: '/whatsapp_authentications/send_code',
  apiContactRoute: '/contact',
  apiViewCountRoute: '/view-count',
  apiCorporateEventRoute: '/corporate-events/:id',
  apiCorporateEventCategoryOptionsRoute: '/corporate-events/:id/category-options',
  apiConfirmProceedRoute: '/confirm-proceed',
  apiRequiredInfosRoute: '/required-infos',
  apiRescheduleTripRoute: '/reschedules/:id',
  apiAvailableDatesRoute: '/available-dates',
  apiMeRoute: '/me',
  apiUserRefundsRoute: '/user-refunds',
  apiGiftCardRedemptionsRoute: '/gift-card-redemptions',
  apiGiftCardRedemptionsRedeemRoute: '/gift-card-redemptions',
  apiAccountTransactionsRoute: '/account-transactions',
  apiOrderItemsRoute: '/order-items',
  apiOrderItemRoute: '/order-items/:id',
  apiCsrfTokenRoute: '/csrf-token',
  apiInvoiceRoute: '/invoices/:id',
  apiInvoicesRoute: '/invoices',
  apiOrderRoute: '/orders/:id',
  apiOrderCancelRoute: '/orders/:id/cancel',
  apiOrderDeclineRoute: '/orders/:id/decline',
  apiOrderItemChangeRequestsRoute: '/order_item_change_requests',
  apiOrderCalculatePriceChangeRoute: '/orders/:id/calculate_price_change',
  apiPromotionCodeRedemptionsRoute: '/promotion-code-redemptions',
  apiPromotionCodeRedemptionRoute: '/promotion-code-redemptions/:id',
  apiPromotionCodeRedemptionsCorporateRoute: '/promotion-code-redemptions/corporate-redeem',
  apiCheckoutCalculatePriceRoute: '/checkouts/calculate_price',
  apiCheckoutAvailableRoute: '/checkouts/available',
  apiCheckoutPayRoute: '/checkouts/pay',
  apiCheckoutPayOtpRoute: '/checkouts/pay_otp',
  apiCheckoutPayFailRoute: '/checkouts/pay_fail',
  apiCheckoutFinalizeRoute: '/checkouts/finalize',
  apiCartRoute: '/cart',
  apiCartClearRoute: '/cart/clear',
  apiImagesRoute: '/images',
  apiGiftCardRoute: '/gift-cards/:id',
  apiGiftCardsRoute: '/gift-cards',
  apiDetailedTimeslotsRoute: '/detailed-timeslots',
  apiPromotionCodesRoute: '/promotion-codes',
  apiSupplierRoute: '/suppliers/:id',
  apiActivityRoute: '/activities/:id',
  apiActivityCategoryOptionsRoute: '/activities/:id/category-options',
  apiNearestActivityRoute: '/activities/:id/nearest',
  apiFaqFilterRoute: '/faqs/filter',
  apiFaqsRoute: '/faqs',
  apiFaqRoute: '/faqs/:id',
  apiTagRoute: '/tags/:id',
  apiTagsRoute: '/tags',
  apiHomePageRoute: '/page/home',
  apiPageActivityRoute: '/page/activities/:id',
  apiPageCollectionRoute: '/page/collections/:id',
  apiOptionAvailabilityRoute: '/options/:id/availability',
  apiOptionPricesRoute: '/options/:id/prices',
  apiOptionCalculatePriceRoute: '/options/:id/calculate-price',
  apiOptionSimilarRoute: '/options/:id/similar',
  apiCustomerReviewsRoute: '/customer-reviews',
  apiTotalCustomersRoute: '/total-customers',
  apiSiteReviewsRoute: '/site-reviews',
  apiCollectionsRoute: '/collections',
  apiCollectionRoute: '/collections/:id',
  apiRelatedCollectionsRoute: '/collections/:id/related',
  apiExploreCollectionsRoute: '/collections/explore',
  apiCollectionCategoryOptionsRoute: '/collections/:id/category-options',
  apiCollectionFeaturedGiftOptionsRoute: '/collections/:id/gift-options',
  apiCategoryRoute: '/categories/:id',
  apiBlogsRoute: '/blogs',
  apiBlogFilterItemsRoute: '/blogs/filter_items',
  apiBlogRoute: '/blogs/:id',
  apiCityRoute: '/cities/:id',
  apiCityFeaturedCollectionOptionsRoute: '/cities/:id/featured-collection-options',
  apiCountryRoute: '/countries/:id',
  apiCountryFeaturedCollectionOptionsRoute: '/countries/:id/featured-collection-options',
  apiCountryTopCityOptionsRoute: '/countries/:id/top-city-options',
  apiPageCityRoute: '/page/cities/:id',
  apiPageOptionRoute: '/page/options/:id',
  apiPageCountryRoute: '/page/countries/:id',
  apiOptionRoute: '/options/:id',
  apiOptionsRoute: '/options',
  apiSimilarOptionsRoute: '/options/:id/similar',
  apiInitialDataRoute: '/initial_data',
  apiSubscribesRoute: '/subscribes',
  apiCorporateInquiriesRoute: '/corporate-inquiries',
  apiLogoutRoute: '/logout',
  apiFilterRoute: '/filter',
  apiFilterItemsRoute: '/filter/items',
  apiFilterRecordRoute: '/filter/record',
  apiFilterCityCollectionRoute: '/filter/city-collection',
  apiReviewFindRoute: '/reviews/find',
  apiReviewsRoute: '/reviews',
  apiFeedbacksRoute: '/feedbacks',
  apiSuppliersRoute: '/suppliers',
  apiRedeemGiftExperiencesRoute: '/redeem_gift_experiences',
  apiCountriesRoute: '/countries',
  apiCitiesRoute: '/cities',
  apiActivitiesRoute: '/activities',
  apiTravelItinerariesRoute: '/travel_itineraries',
  apiTravelItineraryRoute: '/travel_itineraries/:id',
  apiTravelItineraryIncreaseShareCountRoute: '/travel_itineraries/:id/increase_share_count',
};

const appRoutes = {
  notFoundRoute: '/404',
  laterPaymentRoute: '/later-payment/:id',
  invoiceRoute: '/invoices/:id',
  invoiceSuccessRoute: '/invoices/:id/success',
  aboutRoute: '/about',
  ourMissionRoute: '/our-mission',
  sustainabilityRoute: '/sustainability',
  ourCurationRoute: '/our-curation',
  ourCarbonCommitmentRoute: '/our-carbon-commitment',
  betterTravellersRoute: '/better-for-travellers',
  betterLocalCommunitiesRoute: '/better-for-local-communities',
  betterEnvironmentRoute: '/better-for-the-environment',
  newReviewRoute: '/reviews/new',
  accountGiftCodesRoute: '/account/gift-codes',
  accountGiftCodeRoute: '/account/gift-codes/:id',
  accountCreditsRoute: '/account/credits',
  accountPhoneNumberRoute: '/account/phone-number',
  accountProfileRoute: '/account/profile',
  accountReferralRoute: '/account/referral',
  accountTripsRoute: '/account/trips',
  accountTripRoute: '/account/trips/:id',
  accountTripReceiptRoute: '/account/trips/:id/receipt',
  accountRefundConfirmationRoute: '/account/trips/:id/refund/confirmation',
  accountRefundRoute: '/account/trips/:id/refund',
  accountProceedConfirmationRoute: '/account/trips/:id/proceed/confirmation',
  accountProceedRoute: '/account/trips/:id/proceed',
  accountRequiredInfoRoute: '/trips/:id/required_info',
  accountTripCancelRoute: '/account/trips/:id/cancel',
  accountTripCancelSuccessRoute: '/account/trips/:id/cancel/success',
  accountTripAddGuestRoute: '/account/trips/:id/add-guest',
  accountTripAddGuestSuccessRoute: '/account/trips/:id/add-guest/success',
  accountTripChangeDateRoute: '/account/trips/:id/change-date',
  accountTripChangeDateSuccessRoute: '/account/trips/:id/change-date/success',
  activitiesRoute: '/adventures',
  activityRoute: '/adventures/:id',
  cateActivityRoute: '/adventures/:id/:cateId',
  cateBlogsRoute: '/magazine/:id',
  checkoutRoute: '/checkout',
  checkoutFinalizingRoute: '/checkout/finalizing',
  checkoutConfirmationRoute: '/checkout/confirmation',
  cityRoute: '/cities/:id',
  collectionRoute: '/collections/:id',
  tipsCollectionRoute: '/collections/:id/tips',
  cateCollectionRoute: '/collections/:id/:cateId',
  countryCityCollectionRoute: '/:countryCode/:citySlug/:id',
  countryCityCateCollectionRoute: '/:countryCode/:citySlug/:id/:cateId',
  collectionsCityRoute: '/cities/:id/collections',
  activitiesCityRoute: '/cities/:id/activities',
  placesCityRoute: '/cities/:id/places-of-interest',
  contactRoute: '/contact',
  corporateRoute: '/corporate-experiences',
  countryRoute: '/countries/:id',
  collectionsCountryRoute: '/countries/:id/collections',
  placesCountryRoute: '/countries/:id/places-of-interest',
  activitiesCountryRoute: '/countries/:id/activities',
  giftsRoute: '/gifts',
  giftsSendRoute: '/gifts/send',
  covidPolicyRoute: '/covid-policy',
  creatorsCommunityRoute: '/creators-community',
  optionRoute: '/options/:id',
  experienceRoute: '/experiences/:id',
  ourQualityRoute: '/our-quality',
  privacyRoute: '/privacy',
  promotionsRoute: '/promotions',
  redeemGiftExperiencesRoute: '/redeem-gift-experiences',
  inviteRoute: '/invite',
  rootRoute: '/',
  searchRoute: '/search',
  signupRoute: '/users/signup',
  loginRoute: '/users/login',
  loginWhatsappRoute: '/users/login/whatsapp',
  forgotPasswordRoute: '/users/password/new',
  editPasswordRoute: '/users/password/edit',
  sitemapRoute: '/sitemap',
  termsRoute: '/terms',
  triedAndTestedRoute: '/tried-and-tested',
  hostTncRoute: '/host-tnc',
  creatorTncRoute: '/creator-tnc',
  corporateEventRoute: '/corp-events/:id',
  customerReviewsRoute: '/customer-reviews',
  operatorRoute: '/operators/:id',
  itineraryRoute: '/itineraries/:id',
  magazinesRoute: '/magazine/',
  magazineTagRoute: '/magazine/tags/:id',
  shortMagazineRoute: '/magazine/a/:id',
  magazineCateRoute: '/magazine/:cate',
  magazineCateKindRoute: '/magazine/:cate/:kind',
  magazineRoute: '/magazine/:cate/:kind/:id',
  chatConversationRoute: '/chat/:id',
  chatRoute: '/chat',
};

function buildRoutes<T extends { [key: string]: string }>(paths: T, baseUrl: string) {
  const keys = Object.keys(paths) as Array<keyof T>;
  return keys.reduce<Routes<T>>((memo, key) => {
    memo[key] = buildRoute(String(paths[key]), baseUrl);
    return memo;
  }, {} as Routes<T>);
}

export const ApiRoutes = buildRoutes<typeof apiRoutes>(apiRoutes, API_BASE_URL);
export const AppRoutes = buildRoutes<typeof appRoutes>(appRoutes, APP_BASE_URL);
export const AdminRoutes = buildRoutes<typeof adminRoutes>(adminRoutes, ADMIN_BASE_URL);
export function isActiveAppRoute(key: keyof typeof appRoutes, url: string): boolean {
  return !!match(appRoutes[key], { decode: decodeURIComponent })(url);
}
