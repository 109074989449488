import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import useQuery from 'src/hooks/useQuery';
import { CorporateEvent, GiftExperienceCode } from 'src/types';
import { ApiRoutes } from 'src/lib/routes';
import useCart from 'src/hooks/useCart';
import useCurrentUser from 'src/hooks/useCurrentUser';

export const GiftExperienceCodeContext = React.createContext<{
  giftExperienceCode: GiftExperienceCode | undefined;
  isLoading: boolean;
  setParams: React.Dispatch<React.SetStateAction<{ code?: string; gift_exp?: '0' | '1' }>>;
  corporateEvent: CorporateEvent | undefined;
  setCorporateEvent: React.Dispatch<React.SetStateAction<CorporateEvent | undefined>>;
}>({
  giftExperienceCode: undefined,
  isLoading: false,
  setParams: () => {},
  corporateEvent: undefined,
  setCorporateEvent: () => {},
});

const GiftExperienceCodeProvider: React.ReactFCWithChildren = ({ children }) => {
  const [currentUser] = useCurrentUser()
  const router = useRouter();
  const { gdc } = router.query;
  const [cart] = useCart();
  const [params, setParams] = useState<{ code?: string; }>({
    code: gdc ? String(gdc) : undefined
  });
  const [corporateEvent, setCorporateEvent] = useState<CorporateEvent>();
  const { data: promotionCode, isLoading } = useQuery<GiftExperienceCode>(
    params.code && currentUser
      ? ApiRoutes.apiGiftEpxerienceCodeRoute(
          { id: String(params.code) }
        ).toUrl()
      : null,
      {
        fetcherOpts: {
          corsEnable: true
        }
      }
  );

  useEffect(() => {
    if (gdc) {
      setParams({ code: String(gdc) });
    }
  }, [gdc]);

  return (
    <GiftExperienceCodeContext.Provider
      value={{
        giftExperienceCode:
          router.pathname === '/checkout'
            ? promotionCode && promotionCode.optionId === cart.orderItem?.supplierActivityOptionId
              ? promotionCode
              : undefined
            : promotionCode,
        isLoading,
        setParams,
        corporateEvent,
        setCorporateEvent,
      }}
    >
      {children}
    </GiftExperienceCodeContext.Provider>
  );
};

export default GiftExperienceCodeProvider;
