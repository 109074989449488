import { useState, useRef, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import useInitialData from 'src/hooks/useInitialData';
import useCurrentCurrency from 'src/hooks/useCurrentCurrency';
import { FiChevronDown } from 'react-icons/fi';
import { Overlay } from 'react-bootstrap';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import classNames from 'classnames';
import { sortBy } from 'lodash-es';
import currencies from 'src/data/currencies';
import { CurrencySelectorContext } from 'src/contexts/CurrencySelectorProvider';

type Props = {
  variant?: 'white' | 'black';
};

const DISABLED_ROUTES = [
  '/invoices/[id]',
  '/later-payment/[id]',
  '/account/trips/[id]/change-date',
  '/account/trips/[id]/add-guest',
];

export const ListCurrency: React.FC = () => {
  const initialData = useInitialData();
  const orderedCurrencies = sortBy(
    currencies.filter((o) => initialData.checkoutSupportedCurrencies.includes(o.code)),
    [(o) => o.fullName]
  );
  const router = useRouter();
  const currentCurrency = useCurrentCurrency();
  const { isDisabled } = useContext(CurrencySelectorContext)

  return (
    <>
      {orderedCurrencies.map((currency, index) =>
        currency ? (
          <div
            key={currency.code}
            onClick={() => {
              if (isDisabled) return;
              Cookies.set('currency', currency.code, {
                path: '/',
                domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
              });
              router.reload();
            }}
            className={classNames('tw-cursor-pointer tw-leading-none hover:tw-text-brand-navy', {
              'tw-text-brand-navy': currentCurrency === currency.code,
              'tw-text-grey': currentCurrency !== currency.code,
              'tw-mt-1.5': index > 0,
            })}
          >
            {currency.fullName} ({currency.code})
          </div>
        ) : null
      )}
    </>
  );
};

const CurrencySelector: React.FC<Props> = () => {
  const [show, setShow] = useState(false);
  const currentCurrency = useCurrentCurrency();
  const eleRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const { isDisabled, setIsDisabled } = useContext(CurrencySelectorContext)
  const router = useRouter();

  useOnClickOutside(eleRef, (eventTarget) => {
    if (overlayRef.current?.contains(eventTarget)) {
      return;
    }
    setShow(false);
  });

  useEffect(() => {
    if (router.isReady) {
      const disableSelector = () => {
        if (DISABLED_ROUTES.includes(router.pathname)) {
          setIsDisabled(true)
        } else {
          setIsDisabled(false)
        }
      }

      disableSelector()
      router.events.on('routeChangeComplete', disableSelector)

      return () => {
        router.events.off('routeChangeComplete', disableSelector)
      }
    }
  }, [router])

  return (
    <div ref={eleRef}>
      <div className="tw-cursor-pointer" ref={buttonRef} onClick={() => {
        if (isDisabled) return;
        setShow(true)
      }}>
        <span className="tw-uppercase tw-font-semibold tw-font-styleSans">{currentCurrency}</span>
        <FiChevronDown className={`tw-text-brand-navy`} size={16} />
      </div>

      <Overlay
        popperConfig={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 0],
              },
            },
          ],
        }}
        placement="bottom"
        target={buttonRef.current}
        show={show}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            ref={(e) => {
              props.ref(e);
              overlayRef.current = e;
            }}
            style={props.style}
            className="tw-pt-2.5 tw-z-1200 tw-w-60"
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
          >
            <div className="tw-bg-brand-yellow tw-px-8 tw-py-4 tw-font-styleSans tw-font-semibold tw-rounded-lg tw-text-center">
              <ListCurrency />
            </div>
          </div>
        )}
      </Overlay>
    </div>
  );
};

export default CurrencySelector;
