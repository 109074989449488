import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Country, City } from 'src/types';
import { Overlay } from 'react-bootstrap';
import Destinations from 'src/components/Destinations';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { AppRoutes } from 'src/lib/routes';
import ImageWithFallback from 'src/components/ImageWithFallback';
import { FiChevronDown } from 'react-icons/fi';
import classNames from 'classnames';
import useBreakpoints from 'src/hooks/useBreakpoints';
import ClientOnlyPortal from 'src/components/ClientOnlyPortal';
import useFilter from 'src/hooks/useFilter';

type Props = {
  countries: Country[];
  cities: Record<number, City[]>;
};

const DestinationDropdown: React.FC<Props> = ({ countries, cities }) => {
  const { isScreenMd } = useBreakpoints()
  const [show, setShow] = useState(false);
  const buttonRef = useRef(null);
  const eleRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const router = useRouter();
  const { setShouldCloseFilterItem } = useFilter()

  const sortedCountries = useMemo(() => {
    const countryIndo = countries.find(o => o.slug === 'indonesia')
    const countryMalay = countries.find(o => o.slug === 'malaysia')
    const countrySriLanka = countries.find(o => o.slug === 'sri-lanka')
    const countryTaiwan = countries.find(o => o.slug === 'taiwan')
    const countryVietnam = countries.find(o => o.slug === 'vietnam')
    const countryThai = countries.find(o => o.slug === 'thailand')
    const countrySin = countries.find(o => o.slug === 'singapore')

    return [
      [countryIndo].filter(v => v),
      [countryMalay, countrySin].filter(v => v),
      [countrySriLanka].filter(v => v),
      [countryTaiwan, countryThai].filter(v => v),
      [countryVietnam].filter(v => v)
    ]
  }, [countries.length])

  useOnClickOutside(eleRef, (eventTarget) => {
    if (overlayRef.current?.contains(eventTarget)) {
      return;
    }
    setShow(false);
  });

  useEffect(() => {
    if (router.isReady) {
      router.events.on('routeChangeComplete', () => {
        setShow(false);
      });
    }
  }, [router]);

  return (
    <>
      <div ref={eleRef}>
        <div
          className="tw-cursor-pointer"
          ref={buttonRef}
          onClick={() => setShow(true)}
          onMouseEnter={() => {
            setShow(true)
            setShouldCloseFilterItem(prev => prev + 1)
          }}
          onMouseLeave={() => setShow(false)}
        >
          <span className="tw-text-brand-navy">Destinations</span>
          <FiChevronDown size={16} className="tw-text-brand-navy" />
        </div>

        <Overlay
          popperConfig={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -3],
                },
              },
            ],
          }}
          placement="bottom"
          target={buttonRef.current}
          show={show}
        >
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              ref={(e) => {
                props.ref(e);
                overlayRef.current = e;
              }}
              style={{
                ...props.style,
                width: 'calc(100% - 5px)'
              }}
              className="tw-pt-7 tw-z-1200"
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              <div className="tw-bg-brand-yellow tw-p-8 tw-font-styleSans">
                <div className="container tw-flex">
                  <div className="tw--ml-3 tw-flex tw-flex-grow">
                    {sortedCountries.map((countries, index) => {
                      return (
                        <div className="tw-px-3 tw-flex-shrink-0 tw-w-1/5" key={index}>
                          {countries.map((country, i) => country ? (
                            <div key={country.id} className={i > 0 ? 'tw-mt-8' : ''}>
                              {country.slug === 'singapore' ? (
                                <Link legacyBehavior href={AppRoutes.cityRoute({ id: cities[country.id][0]?.slug as string }).toPath()}>
                                  <a className="tw-text-xl tw-mb-2 tw-text-brand-navy tw-font-semibold tw-leading-none hover:tw-underline">{country.name}</a>
                                </Link>
                              ) : (
                                <>
                                  <Link legacyBehavior href={AppRoutes.countryRoute({ id: country.slug }).toPath()}>
                                    <a className="tw-text-xl tw-mb-2 tw-text-brand-navy tw-font-semibold tw-leading-none hover:tw-underline">{country.name}</a>
                                  </Link>
                                  {cities[country.id].map((city) => (
                                    <div key={city.id}>
                                      <Link legacyBehavior href={AppRoutes.cityRoute({ id: city.slug }).toPath()}>
                                        <a className="tw-text-brand-navy hover:tw-underline tw-break-words">
                                          {city.name}
                                        </a>
                                      </Link>
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                          ) : null)}
                        </div>
                      )
                    })}
                  </div>
                  <div className="tw-border-l tw-border-grey-lighter tw-ml-4 tw-mr-6 tw-flex-shrink-0">
                    <div></div>
                  </div>
                  <div className="tw-flex-grow tw-max-w-md">
                    <div className="tw-text-2xl tw-font-semibold tw-text-center tw-mb-8">Most popular</div>
                    <div className="tw-flex">
                      {cities[0].slice(0, 3).map((city) => (
                        <Link legacyBehavior key={city.id} href={AppRoutes.cityRoute({ id: city.slug }).toPath()} passHref>
                          <a
                            className="tw-block col-4 tw-px-2 tw-cursor-pointer tw-text-brand-navy"
                          >
                            <div className="tw-border tw-border-brand-green tw-rounded-lg">
                              <ImageWithFallback
                                record={city.image}
                                imgField="webpSmUrl"
                                alt={city.name}
                                className="tw-static tw-block tw-h-48 tw-w-full tw-object-cover tw-rounded-lg"
                                fill
                              />
                            </div>
                            <div className="tw-mt-4 tw-font-semibold tw-text-center">{city.name}</div>
                          </a>
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Overlay>
      </div>

      <ClientOnlyPortal selector="#bottom-body">
        {isScreenMd ? (
          <div
            className={classNames(
              'tw-bg-black tw-bottom-0 tw-left-0 tw-opacity-30 tw-fixed tw-w-screen tw-z-101',
              { 'tw-hidden': !show }
            )}
            style={{ height: 'calc(100vh - 72px)' }}
          ></div>
        ) : null}
      </ClientOnlyPortal>
    </>
  );
};

export default DestinationDropdown;
