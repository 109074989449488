import { useState, useRef, useEffect, useContext } from 'react';
import { FiChevronDown, FiPlus, FiMinus, FiX } from 'react-icons/fi';
import { Overlay, Popover, Modal } from 'react-bootstrap';
import { snakeCase, union } from 'lodash-es';
import classNames from 'classnames';
import { FilterOption, FilterParams } from 'src/types';
import Line from './Line';
import useBreakpoints from 'src/hooks/useBreakpoints';
import useFilter from 'src/hooks/useFilter';
import { defaultValue as filterDefaultValue } from 'src/contexts/FilterProvider';
import ControlButtons from './ControlButtons';
import { FilterItemContext } from 'src/contexts/FilterItemProvider';

export const ItemInner: React.FC<
  Omit<Props, 'label' | 'showFilter'> & {
    currentValue?: string | number | string[] | number[] | (number | string | undefined)[];
    prefixFieldName?: string;
  }
> = ({
  isNested,
  options,
  inputType,
  maxNumShownOption,
  currentValue,
  fieldName,
  prefixFieldName,
}) => {
  const { setFilterParams } = useFilter();
  const checkboxClass = prefixFieldName
    ? `js-${prefixFieldName}-checbox-indeterminate`
    : 'js-checkbox-indeterminate';
  const containerClass = prefixFieldName
    ? `js-${prefixFieldName}-container-checbox-indeterminate`
    : 'js-container-checkbox-indeterminate';
  const childCheckboxClass = prefixFieldName
    ? `js-${prefixFieldName}-child-checbox-indeterminate`
    : 'js-child-checkbox-indeterminate';
  const rowCheckboxClass = prefixFieldName
    ? `js-${prefixFieldName}-row-checbox-indeterminate`
    : 'js-row-checkbox-indeterminate';
  const [showAll, setShowAll] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState<{ [k: string]: boolean }>({});
  const handleChangeIndeterminateCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    optionValue: string | number
  ) => {
    const checkboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(`.${checkboxClass}`);
    let check: HTMLInputElement | null | undefined = e.target;

    //  exit if change event did not come from
    //  our list of indeterminateCheckboxes
    if (Array.from(checkboxes).indexOf(check) === -1) return;

    //  check/unchek children (includes check itself)
    let children = check.dataset.root
      ? check
          .closest(`.${containerClass}`)
          ?.querySelector(`.${childCheckboxClass}`)
          ?.querySelectorAll('input') || []
      : check.closest(`.${rowCheckboxClass}`)?.querySelectorAll('input') || [];

    let childValues = Array.from(children).map((o) => o.value);
    const checked = check?.checked;
    setFilterParams((prev) => {
      return {
        ...prev,
        activityType: checked
          ? union(prev.activityType || [], childValues)
          : (prev.activityType || []).filter((v) => !childValues.includes(v)),
      };
    });
    children.forEach(function (child) {
      child.checked = check?.checked || false;
    });

    //  traverse up from target check
    while (check) {
      //  find parent and sibling checkboxes (quick'n'dirty)
      let parent: HTMLInputElement | null | undefined = check
        .closest(`.${containerClass}`)
        ?.querySelector('input');
      let childCheckboxContainer = check
        .closest(`.${containerClass}`)
        ?.querySelector(`.${childCheckboxClass}`);
      let siblings: HTMLInputElement[] = parent
        ? Array.from(childCheckboxContainer?.querySelectorAll('input') || [])
        : [];

      //  get checked state of siblings
      //  are every or some siblings checked (using Boolean as test function)
      let checkStatus = siblings.map(function (check) {
        return check?.checked || false;
      });
      let every = checkStatus.every(Boolean);
      let some = checkStatus.some(Boolean);

      //  check parent if all siblings are checked
      //  set indeterminate if not all and not none are checked
      if (parent) {
        parent.checked = every;
        const parentValue = parent.value;
        setFilterParams((prev) => {
          return {
            ...prev,
            activityType: every
              ? union(prev.activityType || [], [parentValue])
              : (prev.activityType || []).filter((v) => v !== parentValue),
          };
        });
        parent.indeterminate = !every && every !== some;
      }

      if (check.checked || check.indeterminate) {
        setActiveAccordion((prev) => ({ ...prev, [optionValue]: true }));
      } else {
        setActiveAccordion((prev) => ({ ...prev, [optionValue]: false }));
      }

      //  prepare for nex loop
      check = check != parent ? parent : undefined;
    }
  };

  return (
    <>
      {options.map((option, index) => (
        <div
          key={option.value}
          className={classNames('tw-font-sans', {
            'tw-hidden': !showAll && maxNumShownOption && index >= maxNumShownOption,
          })}
        >
          {isNested && option.children && option.children.length > 0 ? (
            <div className={containerClass}>
              <div className="tw-cursor-pointer tw-flex tw-justify-between tw-items-center">
                <Line
                  option={option}
                  inputType={inputType}
                  className={classNames(`${rowCheckboxClass}`, { 'tw-mt-2': index > 0 })}
                  inputClassName={checkboxClass}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChangeIndeterminateCheckbox(e, option.value)
                  }
                  isRoot
                  currentValue={currentValue}
                  fieldName={fieldName}
                  prefixFieldName={prefixFieldName}
                />
                <div
                  className={classNames('tw-mb-2 tw-flex-grow text-right tw-cursor-pointer', { 'tw-mt-2': index > 0 })}
                  onClick={() => {
                    setActiveAccordion((prev) => ({
                      ...prev,
                      [option.value]: !prev[option.value],
                    }));
                  }}
                >
                  {activeAccordion[option.value] ? (
                    <span className="tw-text-brand-navy tw-font-semibold tw-text-xl tw-leading-none tw-font-styleSans">-</span>
                  ) : (
                    <span className="tw-text-brand-navy tw-font-semibold tw-text-xl tw-leading-none tw-font-styleSans">+</span>
                  )}
                </div>
              </div>
              <div
                className={`${childCheckboxClass} tw-pl-6 ${
                  activeAccordion[option.value] ? '' : 'tw-hidden'
                }`}
              >
                {option.children.map((co) => (
                  <Line
                    key={co.value}
                    currentValue={currentValue}
                    fieldName={fieldName}
                    prefixFieldName={prefixFieldName}
                    option={co}
                    inputType={inputType}
                    className={rowCheckboxClass}
                    inputClassName={checkboxClass}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleChangeIndeterminateCheckbox(e, option.value)
                    }
                  />
                ))}
              </div>
            </div>
          ) : (
            <Line
              option={option}
              inputType={inputType}
              className={classNames({ 'tw-mt-2': index > 0 })}
              currentValue={currentValue}
              fieldName={fieldName}
              prefixFieldName={prefixFieldName}
            />
          )}
        </div>
      ))}

      {maxNumShownOption && options.length > maxNumShownOption ? (
        <div
          className="tw-mt-4 tw-font-styleSans tw-font-semibold tw-text-brand-navy tw-lowercase tw-cursor-pointer tw-underline"
          onClick={() => setShowAll((prev) => !prev)}
        >
          Show {showAll ? 'less' : 'more'}
        </div>
      ) : null}
    </>
  );
};

type Props = {
  inputType: 'checkbox' | 'radio';
  fieldName: keyof FilterParams;
  label: string;
  options: FilterOption[];
  maxNumShownOption?: number;
  isNested?: boolean;
  hideCounter?: boolean;
  prices?: number[];
  showFilter: boolean;
};

const Item: React.FC<Props> = ({
  label,
  fieldName,
  inputType,
  options,
  isNested,
  maxNumShownOption,
  hideCounter,
  showFilter
}) => {
  const { setShowFilterItem } = useContext(FilterItemContext)
  const { filterParams, filter, shouldCloseFilterItem } = useFilter();
  const itemRef = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const { isScreenMd } = useBreakpoints();
  const currentValue = filterParams[fieldName];
  const numSelected =
    inputType === 'checkbox'
      ? ((currentValue as string[]) || []).length
      : currentValue && currentValue !== filterDefaultValue[fieldName]
      ? 1
      : 0;

  useEffect(() => {
    if (!showFilter && show) {
      setShow(false)
    }
  }, [showFilter, show])

  useEffect(() => {
    if (shouldCloseFilterItem) {
      setShow(false)
    }
  }, [shouldCloseFilterItem])

  useEffect(() => {
    setShowFilterItem(show)
  }, [show, setShowFilterItem])

  return (
    <>
      <div
        ref={itemRef}
        className={classNames(
          'tw-justify-center tw-flex-grow md:tw-flex-grow-0 tw-flex tw-items-center tw-leading-none tw-py-2 tw-px-3 md:tw-px-6 tw-rounded-4xl tw-border tw-cursor-pointer tw-mx-2',
          {
            'tw-bg-brand-green tw-text-brand-navy tw-border-brand-green': numSelected > 0,
            'tw-bg-white tw-border-grey-lighter hover:tw-border-brand-navy': numSelected <= 0,
          }
        )}
        onClick={() => setShow(true)}
      >
        <span className="tw-mr-1 tw-text-sm md:tw-text-base tw-lowercase tw-font-styleSans tw-font-semibold">{label}</span>
        {!hideCounter && numSelected > 0 ? (
          <span className="tw-hidden tw-rounded-full tw-text-center md:tw-inline-block tw-text-xs tw-bg-brand-navy tw-text-brand-green tw-font-semibold tw-mr-1 tw-w-5 tw-h-5 tw-leading-none tw-py-1">
            {numSelected}
          </span>
        ) : null}
        <FiChevronDown
          className="tw-text-brand-navy"
        />
      </div>

      {isScreenMd ? (
        <Overlay
          rootClose={true}
          target={itemRef}
          show={show}
          placement="bottom"
          onHide={() => {
            setShow(false)
            filter()
          }}
          popperConfig={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 4],
                },
              },
            ]
          }}
        >
          <Popover
            id={`popover-filter-item-${snakeCase(label)}`}
            className={`tw-w-72 tw-py-3 tw-px-0 popover-no-arrow tw-font-styleSans tw-z-50`}
          >
            <div className="md:tw-max-h-sm tw-overflow-y-auto tw-relative">
              <Popover.Content className="tw-px-3">
                <ItemInner
                  options={options}
                  inputType={inputType}
                  isNested={isNested}
                  maxNumShownOption={maxNumShownOption}
                  currentValue={currentValue}
                  fieldName={fieldName}
                />
              </Popover.Content>
            </div>
            <ControlButtons setShowPopover={setShow} fieldNames={[fieldName]} className="tw-pt-3 tw-border-t tw-border-grey-lighter tw-px-3" />
          </Popover>
        </Overlay>
      ) : (
        <Modal
          show={show}
          onHide={() => {
            setShow(false)
            filter()
          }}
          className="tw-font-styleSans"
          dialogClassName="modal-dialog-bottom modal-almost-full-height--sp modal-dialog-scrollable modal-lg"
          contentClassName="tw-rounded-tl-2xl tw-rounded-tr-2xl"
        >
          <Modal.Header>
            <div className="modal-title tw-font-semibold">{label}</div>
            <button type="button" className="close" onClick={() => setShow(false)}>
              <FiX strokeWidth={1} size={24} className="tw-text-brand-navy" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <ItemInner
              options={options}
              inputType={inputType}
              isNested={isNested}
              maxNumShownOption={maxNumShownOption}
              currentValue={currentValue}
              fieldName={fieldName}
            />
          </Modal.Body>
          <Modal.Footer>
            <ControlButtons setShowPopover={setShow} fieldNames={[fieldName]} />
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Item;
