import { MutableRefObject } from 'react';
import Slider, { Settings } from 'react-slick';

const SliderOrDiv: React.FC<Settings & { isSlider: boolean; className?: string, sliderRef?: MutableRefObject<Slider | null> }> = ({
  children,
  isSlider,
  className,
  sliderRef,
  ...rest
}) => {
  return isSlider ? (
    <Slider className={className || ''} {...rest} ref={sliderRef}>
      {children}
    </Slider>
  ) : (
    <div className={className || ''}>{children}</div>
  );
};

export default SliderOrDiv;
