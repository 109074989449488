import React, { useState } from 'react';

type TFlash = { alert?: string; notice?: string; ttl?: number };
export const FlashContext = React.createContext<
  [TFlash, React.Dispatch<React.SetStateAction<TFlash>>]
>([{}, () => {}]);

const FlashProvider: React.ReactFCWithChildren = ({ children }) => {
  const [flash, setFlash] = useState({} as TFlash);

  return <FlashContext.Provider value={[flash, setFlash]}>{children}</FlashContext.Provider>;
};

export default FlashProvider;
