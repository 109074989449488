import React from 'react';
import ActionCableType from '@rails/actioncable';
import useCurrentUser from 'src/hooks/useCurrentUser';

export const ActionCableContext = React.createContext<ActionCableType.Consumer | null>(null);

const loadConsumer = async () => {
  const ActionCable = await import('actioncable');
  const consumer = ActionCable.createConsumer(process.env.NEXT_PUBLIC_ACTIONCABLE_URL)
  return consumer
}

const ActionCableProvider: React.ReactFCWithChildren = ({ children }) => {
  const [consumer, setConsumer] = React.useState<ActionCableType.Consumer | null>(null)
  const [currentUser] = useCurrentUser();

  React.useEffect(() => {
    if (typeof currentUser?.id === 'number' && typeof window !== 'undefined' && !consumer) {
      loadConsumer().then((consumer) => {
        setConsumer(consumer)
      })
    }
  }, [consumer, currentUser?.id])

  return (
    <ActionCableContext.Provider value={consumer}>
      {children}
    </ActionCableContext.Provider>
  )
}

export default ActionCableProvider
