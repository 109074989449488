import { useContext } from 'react';
import { TooManyRequestsErrorContext } from 'src/contexts/TooManyRequestsErrorProvider';
import { BasicHttpHeader } from 'src/lib/api_client';

type EnhanceApiClient = <Params, Result>(
  request: (params: Params, headers?: BasicHttpHeader) => Promise<[Result, Response]>
) => (
  params: Params,
  headers?: BasicHttpHeader
) => Promise<[Result, Response]>;

const useEnhanceApiClient: EnhanceApiClient = (request) => {
  const [, setIsError] = useContext(TooManyRequestsErrorContext);
  return async (params, headers) => {
    const result = await request(params, headers);
    const [, response] = result;
    if (response.status === 429) {
      setIsError(true);
    }
    return result;
  }
};

export default useEnhanceApiClient;
