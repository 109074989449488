import React, { useEffect, useState } from 'react';
import { Currency, CheckoutCurrency, checkoutCurrencies } from 'src/types';
import Cookies from 'js-cookie';
import useInitialData from 'src/hooks/useInitialData';
import { MasterCurrency } from 'src/lib/constants'

const COUNTRY_CODE_TO_CURRENCY: { [key: string]: Currency } = {
  AU: 'AUD',
  GB: 'GBP',
  SG: 'SGD',
  MY: 'MYR',
  AT: 'EUR',
  BE: 'EUR',
  HR: 'EUR',
  CY: 'EUR',
  EE: 'EUR',
  FI: 'EUR',
  FR: 'EUR',
  DE: 'EUR',
  GR: 'EUR',
  IE: 'EUR',
  IT: 'EUR',
  LV: 'EUR',
  LT: 'EUR',
  LU: 'EUR',
  MT: 'EUR',
  NL: 'EUR',
  PT: 'EUR',
  SK: 'EUR',
  SI: 'EUR',
  ES: 'EUR',
  TH: 'THB',
  CN: 'CNY',
  JP: 'JPY',
  IN: 'INR',
  NZ: 'NZD',
  CA: 'CAD',
  CH: 'CHF',
}

const DEFAULT_CURRENCY = 'USD'

export const CurrencyContext = React.createContext<[
  Currency,
  React.Dispatch<React.SetStateAction<Currency>>
]>([
  DEFAULT_CURRENCY,
  () => {}
])

const CurrencyProvider: React.ReactFCWithChildren = ({ children }) => {
  const { currentGeolocation } = useInitialData()
  const [currency, setCurrency] = useState<Currency>(
    (checkoutCurrencies.includes(Cookies.get('currency') as CheckoutCurrency) ? Cookies.get('currency') : DEFAULT_CURRENCY) as Currency
  );

  useEffect(() => {
    if (currentGeolocation.countryCode) {
      const currency = COUNTRY_CODE_TO_CURRENCY[currentGeolocation.countryCode]
      const cookieCurrency = Cookies.get('currency')
      if (currency && !cookieCurrency) {
        setCurrency(currency)
      }
    }
  }, [currentGeolocation.countryCode])

  useEffect(() => {
    const timer = setInterval(() => {
      const cookieCurrency = Cookies.get('currency')
      if (cookieCurrency) {
        setCurrency(cookieCurrency as Currency)
      }
    }, 500)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <CurrencyContext.Provider value={[currency, setCurrency]}>
      {children}
    </CurrencyContext.Provider>
  )
}

export default CurrencyProvider
