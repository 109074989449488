import React, { useEffect } from 'react';
import Router from 'next/router';
import useFilter from 'src/hooks/useFilter';

type Cache = {
  page?: { [key: string]: number | undefined };
  scrollPosition?: { [key: string]: { x: number; y: number } };
  shouldScrollRestore?: boolean;
};

const PageIncludesOtherOptions = [
  '/cities/[id]',
  '/collections/[id]',
  '/countries/[id]',
  '/events/[id]',
  '/operators/[id]',
];

export const OtherOptionCacheContext = React.createContext<{
  cache: Cache;
  setCache: React.Dispatch<React.SetStateAction<Cache>>;
}>({
  cache: {},
  setCache: () => {},
});

const OtherOptionCacheProvider: React.ReactFCWithChildren = ({ children }) => {
  const [cache, setCache] = React.useState<Cache>({});
  useEffect(() => {
    Router.beforePopState(() => {
      setCache((prev) => ({
        ...prev,
        shouldScrollRestore: true,
      }));
      return true;
    });

    return () => {
      Router.beforePopState(() => true);
    };
  }, []);
  return (
    <OtherOptionCacheContext.Provider value={{ cache, setCache }}>
      {children}
    </OtherOptionCacheContext.Provider>
  );
};

export default OtherOptionCacheProvider;
