import React, { useState } from 'react';

export const TooManyRequestsErrorContext = React.createContext<
  [boolean, React.Dispatch<React.SetStateAction<boolean>>]
>([false, () => {}]);

const TooManyRequestsErrorProvider: React.ReactFCWithChildren = ({ children }) => {
  const [isTooMany, setIsTooMany] = useState(false);

  return (
    <TooManyRequestsErrorContext.Provider value={[isTooMany, setIsTooMany]}>
      {children}
    </TooManyRequestsErrorContext.Provider>
  );
};

export default TooManyRequestsErrorProvider
