import React, { useEffect, useRef, useState } from 'react';
import { BiVolumeFull, BiVolumeMute, BiExpandAlt } from 'react-icons/bi';
import Player from '@vimeo/player';
import { FiPlay, FiX } from 'react-icons/fi';
import styles from 'src/styles/modules/components/video_card.module.scss';
import classNames from 'classnames';
import EmbeddedVimeo, { Props as VimeoProps } from 'src/components/EmbeddedVimeo';
import ClientOnlyPortal from './ClientOnlyPortal';
import useIsMounted from 'src/hooks/useIsMounted';
import useIsVimeoSupportedCountry from 'src/hooks/useIsVimeoSupportedCountry';
import { StaticImageData } from 'next/image';
import ImageWithFallback from 'src/components/ImageWithFallback';

type Props = {
  containerClassName?: string;
  vimeoId: number | string;
  vimeoConfig?: Omit<VimeoProps, 'vimeoId'>;
  setPlayers?: React.Dispatch<React.SetStateAction<Player[]>>;
  setPlayer?: React.Dispatch<React.SetStateAction<Player | undefined>>;
  unmuteOnPlay?: boolean;
  hideAllButtons?: boolean;
  noVideoImg?: StaticImageData;
};

const VideoCard: React.FC<Props> = ({
  vimeoId,
  vimeoConfig = {},
  containerClassName,
  setPlayers,
  setPlayer,
  unmuteOnPlay,
  hideAllButtons,
  noVideoImg,
}) => {
  const canShowVideo = useIsVimeoSupportedCountry();
  const [isExpanded, setIsExpanded] = useState<boolean>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVolumeOn, setIsVolumeOn] = useState<boolean>();
  const isMounted = useIsMounted();

  const videoEleRef = useRef<HTMLDivElement | null>(null);
  const videoIframeRef = useRef<HTMLIFrameElement | null>(null);
  const videoPlayerRef = useRef<Player | null>(null);
  const videoIframeFullscreenRef = useRef<HTMLIFrameElement | null>(null);
  const videoPlayerFullscreenRef = useRef<Player | null>(null);

  useEffect(() => {
    if (!isMounted) return;
    const iframe = videoIframeRef.current;
    const iframeFullscreen = videoIframeFullscreenRef.current;
    if (!(iframe instanceof HTMLIFrameElement)) return;
    if (!(iframeFullscreen instanceof HTMLIFrameElement)) return;
    if (videoPlayerRef.current) return;

    const player = new Player(iframe);
    videoPlayerRef.current = player;
    setPlayers && setPlayers((prev) => [...prev, player]);
    setPlayer && setPlayer(player);
    videoPlayerFullscreenRef.current = new Player(iframeFullscreen);

    let video = videoPlayerRef.current;
    let disableLoop = false;

    videoPlayerFullscreenRef.current.on('play', function () {
      setIsPlaying(true);
    });
    videoPlayerFullscreenRef.current.on('pause', function () {
      setIsPlaying(false);
    });
    video.on('play', function () {
      setIsPlaying(true);
    });
    video.on('pause', function () {
      setIsPlaying(false);
    });

    if (vimeoConfig.loop !== 1) {
      video.on('timeupdate', function (data) {
        if (disableLoop && data.percent < 0.01) {
          video.pause();
        }
        if (!disableLoop && data.percent > 0.01) {
          disableLoop = true;
        }
      });
    }

    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        setIsExpanded(false);
      }
    });

    if (vimeoConfig.autoplay === 1) {
      setIsPlaying(true);
      video.play().catch((e) => {
        if (e instanceof Error) {
          if (e.name.includes('PlayInterrupted')) {
            setIsPlaying(false);
          }
        }
      });
    }
  }, [vimeoId, isMounted]);

  useEffect(() => {
    if (typeof isExpanded !== 'boolean') return;
    if (isExpanded) {
      videoPlayerRef.current?.pause().then(async () => {
        const time = await videoPlayerRef.current?.getCurrentTime();
        await videoPlayerFullscreenRef.current?.setCurrentTime(time || 0);
        videoPlayerFullscreenRef.current?.play();
      });
    } else {
      videoPlayerFullscreenRef.current?.pause().then(async () => {
        const time = await videoPlayerFullscreenRef.current?.getCurrentTime();
        await videoPlayerRef.current?.setCurrentTime(time || 0);
        videoPlayerRef.current?.play();
      });
    }
  }, [isExpanded]);

  useEffect(() => {
    if (typeof isVolumeOn !== 'boolean') return;
    const playing = isPlaying;
    const expanded = isExpanded;
    videoPlayerRef.current?.setMuted(!isVolumeOn).then(() => {
      if (!expanded && playing) {
        videoPlayerRef.current?.play();
      }
    });
    videoPlayerFullscreenRef.current?.setMuted(!isVolumeOn).then(() => {
      if (expanded && playing) {
        videoPlayerFullscreenRef.current?.play();
      }
    });
  }, [isVolumeOn]);

  return canShowVideo ? (
    <div
      className={`tw-h-full tw-absolute tw-w-full tw-top-0 tw-left-0 ${containerClassName || ''}`}
    >
      <div ref={videoEleRef} className={`${styles.video}`}>
        <EmbeddedVimeo
          ref={videoIframeRef}
          vimeoId={String(vimeoId)}
          {...{
            controls: 0,
            portrait: 0,
            byline: 0,
            muted: 1,
            transparent: 0,
            loop: 0,
            autoplay: 0,
            ...vimeoConfig,
          }}
        />
      </div>
      <div
        className="tw-w-full tw-h-full tw-absolute tw-top-0 tw-left-0 tw-z-20"
        onClick={() => {
          if (hideAllButtons) return;
          videoPlayerRef.current?.pause();
        }}
      ></div>
      <div
        className={`${
          hideAllButtons ? 'tw-hidden' : styles.button
        } tw-cursor-pointer tw-h-10 tw-w-10 tw-absolute tw-top-5 tw-right-18 tw-border tw-border-white tw-rounded-full`}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          setIsVolumeOn((prev) => !prev);
        }}
      >
        <div className="tw-bg-black tw-opacity-30 tw-rounded-full tw-w-full tw-h-full"></div>
        {isVolumeOn ? (
          <BiVolumeFull
            size={20}
            className="tw-text-white tw-absolute tw-transform tw-top-1/2 tw--translate-y-1/2 tw-left-1/2 tw--translate-x-1/2"
          />
        ) : (
          <BiVolumeMute
            size={20}
            className="tw-text-white tw-absolute tw-transform tw-top-1/2 tw--translate-y-1/2 tw-left-1/2 tw--translate-x-1/2"
          />
        )}
      </div>
      <div
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          setIsExpanded(true);
        }}
        className={`${
          hideAllButtons ? 'tw-hidden' : styles.button
        } tw-cursor-pointer tw-h-10 tw-w-10 tw-absolute tw-top-5 tw-right-5 tw-border tw-border-white tw-rounded-full`}
      >
        <div className="tw-bg-black tw-opacity-30 tw-rounded-full tw-w-full tw-h-full"></div>
        <BiExpandAlt
          size={20}
          className="tw-text-white tw-absolute tw-transform tw-top-1/2 tw--translate-y-1/2 tw-left-1/2 tw--translate-x-1/2"
        />
      </div>

      <div
        onClick={() => {
          videoPlayerRef.current?.play();
          if (unmuteOnPlay) {
            setIsVolumeOn(true);
          }
        }}
        className={classNames(
          `${
            hideAllButtons ? 'tw-hidden' : styles.button
          } tw-cursor-pointer tw-h-14 tw-w-14 tw-absolute tw-transform tw-top-1/2 tw--translate-y-1/2 tw-left-1/2 tw--translate-x-1/2 tw-border tw-border-white tw-rounded-full`,
          { 'tw-hidden': isPlaying }
        )}
      >
        <div className="tw-bg-black tw-opacity-30 tw-rounded-full tw-w-full tw-h-full"></div>
        <FiPlay
          strokeWidth={1}
          size={28}
          className="tw-text-white tw-absolute tw-transform tw-top-1/2 tw--translate-y-1/2 tw-left-1/2 tw--translate-x-1/2"
        />
      </div>

      <ClientOnlyPortal selector="#bottom-body">
        <div className={`${isExpanded ? styles.fullScreen : 'tw-hidden'}`}>
          <EmbeddedVimeo
            ref={videoIframeFullscreenRef}
            vimeoId={String(vimeoId)}
            {...{
              controls: 0,
              portrait: 0,
              byline: 0,
              muted: 1,
              transparent: 0,
              loop: 0,
              ...vimeoConfig,
              autoplay: 0,
            }}
          />

          <div
            className="tw-w-full tw-h-full tw-fixed tw-top-0 tw-left-0 tw-z-1051"
            onClick={() => {
              videoPlayerFullscreenRef.current?.pause();
            }}
          ></div>

          <div
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
              setIsVolumeOn((prev) => !prev);
            }}
            className={classNames(
              `tw-cursor-pointer tw-z-1052 tw-h-10 md:tw-h-14 tw-w-10 md:tw-w-14 tw-fixed tw-top-5 md:tw-top-10 tw-right-20 md:tw-right-28 tw-border tw-border-white tw-rounded-full`,
              { 'tw-hidden': !isExpanded }
            )}
          >
            <div className="tw-bg-black tw-opacity-30 tw-rounded-full tw-w-full tw-h-full"></div>
            {isVolumeOn ? (
              <BiVolumeFull
                size={26}
                className="tw-text-white tw-absolute tw-transform tw-top-1/2 tw--translate-y-1/2 tw-left-1/2 tw--translate-x-1/2"
              />
            ) : (
              <BiVolumeMute
                size={26}
                className="tw-text-white tw-absolute tw-transform tw-top-1/2 tw--translate-y-1/2 tw-left-1/2 tw--translate-x-1/2"
              />
            )}
          </div>

          <div
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
              videoPlayerFullscreenRef.current?.play();
            }}
            className={classNames(
              `tw-cursor-pointer tw-z-1052 tw-h-12 md:tw-h-14 tw-w-12 md:tw-w-14 tw-fixed tw-transform tw-top-1/2 tw--translate-y-1/2 tw-left-1/2 tw--translate-x-1/2 tw-border tw-border-white tw-rounded-full`,
              { 'tw-hidden': !isExpanded || isPlaying }
            )}
          >
            <div className="tw-bg-black tw-opacity-30 tw-rounded-full tw-w-full tw-h-full"></div>
            <FiPlay
              strokeWidth={1}
              size={28}
              className="tw-text-white tw-absolute tw-transform tw-top-1/2 tw--translate-y-1/2 tw-left-1/2 tw--translate-x-1/2"
            />
          </div>

          <div
            onClick={() => setIsExpanded(false)}
            className={classNames(
              `tw-cursor-pointer tw-z-1052 tw-h-10 md:tw-h-14 tw-w-10 md:tw-w-14 tw-fixed tw-top-5 md:tw-top-10 tw-right-5 md:tw-right-10 tw-border tw-border-white tw-rounded-full`,
              { 'tw-hidden': !isExpanded }
            )}
          >
            <div className="tw-bg-black tw-opacity-30 tw-rounded-full tw-w-full tw-h-full"></div>
            <FiX
              size={32}
              className="tw-text-white tw-absolute tw-transform tw-top-1/2 tw--translate-y-1/2 tw-left-1/2 tw--translate-x-1/2"
            />
          </div>
        </div>
      </ClientOnlyPortal>
    </div>
  ) : noVideoImg ? (
    <ImageWithFallback
      src={noVideoImg}
      fill
      alt=""
      className={`tw-block tw-object-cover tw-w-full tw-h-full`}
    />
  ) : null;
};

export default VideoCard;
