import { useCallback } from 'react';
import { ApiError } from './useQuery';
import useEnhanceApiClient from './useEnhanceApiClient';
import ApiClient from 'src/lib/api_client';

const useCsrfTokenUpdate = () => {
  const getCsrfToken = useEnhanceApiClient(ApiClient.getCsrfToken);
  return useCallback<() => Promise<string | never>>(() => {
    return getCsrfToken(undefined)
      .then((result) => {
        const [json, response] = result
        if ('token' in json) {
          return json.token;
        } else {
          const error = new ApiError(
            'error' in json ? json.error : 'An internal error occurred'
          );
          error.status = response.status
          throw error;
        }
      })
  }, []);
};

export default useCsrfTokenUpdate;
