import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';

export type SearchBarParams = { qs: string; numGuest: number | null; numAdult: number | null; numChild: number | null; dates: string[] };
export type SearchBarContext = [
  SearchBarParams,
  React.Dispatch<React.SetStateAction<SearchBarParams>>,
  SearchBarParams,
];

const DEFAULT_VALUE = { qs: '', numGuest: null, numAdult: null, numChild: null, dates: [] };
export const SearchBarStateContext = React.createContext<SearchBarContext>([
  DEFAULT_VALUE,
  () => {},
  DEFAULT_VALUE
]);

const GlobalDateNumGuestProvider: React.ReactFCWithChildren = ({ children }) => {
  const router = useRouter()
  const [initialParams, setInitialParams] = useState<SearchBarParams>(DEFAULT_VALUE)
  const [params, setParams] = useState<SearchBarParams>(DEFAULT_VALUE);
  const json = JSON.stringify(params);

  useEffect(() => {
    if (typeof window === 'object') {
      const data = JSON.parse(window.sessionStorage.getItem('global_params') || JSON.stringify(DEFAULT_VALUE));
      setParams({
        ...data,
        dates: ((data.dates || []) as string[]).filter(d => dayjs(d).isSameOrAfter(dayjs()))
      });
    }
  }, []);

  useEffect(() => {
    if (typeof window === 'object') {
      const data = JSON.parse(window.sessionStorage.getItem('global_params') || JSON.stringify(DEFAULT_VALUE));
      setInitialParams({
        ...data,
        dates: ((data.dates || []) as string[]).filter(d => dayjs(d).isSameOrAfter(dayjs()))
      })
    }
  }, [router.pathname])

  useEffect(() => {
    if (typeof window === 'object') {
      window.sessionStorage.setItem('global_params', json);
    }
  }, [json]);

  return (
    <SearchBarStateContext.Provider value={[params, setParams, initialParams]}>
      {children}
    </SearchBarStateContext.Provider>
  );
};

export default GlobalDateNumGuestProvider;
