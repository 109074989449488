import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { FilterRecord, FilterParams } from 'src/types';
import { AppRoutes } from 'src/lib/routes';
import humps from 'humps';
import { isEqual } from 'lodash-es';

export const filterablePaths = [
  '/cities/[id]',
  '/cities/[id]/tags/[tagId]',
  '/countries/[id]',
  '/countries/[id]/tags/[tagId]',
  '/adventures/[id]',
  '/adventures/[id]/[cateId]',
  '/collections/[id]',
  '/collections/[id]/[cateId]',
  '/[countryCode]/[citySlug]/[id]',
  '/[countryCode]/[citySlug]/[id]/[cateId]',
  '/search'
];

export const filterKeys: (keyof FilterParams)[] = [
  'priceOrder',
  'activityType',
  'time',
  'locationIds',
  'cityIds',
  'suitableFor',
  'prices',
  'childAge',
  'durations',
  'tripType',
  'cityId',
  'countryId',
  'specificId'
];

export const defaultValue: FilterParams = {
  priceOrder: 'recommended'
}

export const FilterContext = React.createContext<{
  filter: () => void;
  filterParams: FilterParams;
  setFilterParams: React.Dispatch<React.SetStateAction<FilterParams>>;
  filterRecord: FilterRecord | undefined;
  setFilterRecord: React.Dispatch<React.SetStateAction<FilterRecord | undefined>>;
  setPrevFilterParams: React.Dispatch<React.SetStateAction<FilterParams>>;
  shouldCloseFilterItem: number;
  setShouldCloseFilterItem: React.Dispatch<React.SetStateAction<number>>;
}>({
  filter: () => {},
  filterParams: {},
  setFilterParams: () => {},
  filterRecord: undefined,
  setFilterRecord: () => {},
  setPrevFilterParams: () => {},
  shouldCloseFilterItem: 0,
  setShouldCloseFilterItem: () => {},
});

const FilterProvider: React.ReactFCWithChildren = ({ children }) => {
  const router = useRouter();
  const [shouldCloseFilterItem, setShouldCloseFilterItem] = useState<number>(0);
  const [params, setParams] = useState<FilterParams>(defaultValue);
  const [prevFilterParams, setPrevFilterParams] = useState<FilterParams>(defaultValue);
  const [record, setRecord] = useState<FilterRecord>();

  const filter = () => {
    if (!record) return;
    const isSame = isEqual(params, prevFilterParams);
    if (isSame) return;
    setPrevFilterParams(params);
    router.push(
      AppRoutes.searchRoute(
        {},
        {
          ...params,
          recordId: record.id,
          recordType: record.type,
          ...(record.parentType === 'City' ? { cityId: record.parentId } : {}),
          ...(record.parentType === 'Country' ? { countryId: record.parentId } : {}),
        }
      ).toUrl(),
      undefined,
      { scroll: false }
    );
  };

  useEffect(() => {
    if (record?.id) {
      const params = filterKeys.reduce((memo, key) => {
        const decamelizedKey = humps.decamelize(key);
        let aryValue = router.query[`${decamelizedKey}[]`];
        aryValue = typeof aryValue === 'string' ? [aryValue] : aryValue;
        memo[key] = aryValue || router.query[decamelizedKey] || defaultValue[key];
        return memo;
      }, {} as { [k: string]: any });

      setParams(params);
      setPrevFilterParams(params);
    }
  }, [record?.id, record?.type, router.query]);

  useEffect(() => {
    const hideFilter = () => {
      if (!filterablePaths.includes(router.pathname) || router.pathname === '/') {
        setRecord(undefined);
      }
    };
    router.events.on('routeChangeComplete', hideFilter);

    return () => {
      router.events.off('routeChangeComplete', hideFilter);
    };
  }, [router.pathname]);

  return (
    <FilterContext.Provider
      value={{
        filter,
        filterParams: params,
        setFilterParams: setParams,
        filterRecord: record,
        setFilterRecord: setRecord,
        setPrevFilterParams,
        shouldCloseFilterItem,
        setShouldCloseFilterItem,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
